<template>
  <div class="aside-box" :style="{ top: 100 - (asideNav.length * 9 )+ '%' }">
    <div v-for="(item, index) in asideNav" :key="index">
      <div class="icon-item">
        <template v-if="item.show_type == 2 || item.show_type == 1">
          <el-popover trigger="hover" :width="item.show_type == 2 ? 120 : 'auto'" placement="left">
            <template v-if="item.show_type == 2">
              <span>{{ item.show_type_text }}</span>
            </template>
            <template v-else>
              <img :src="item.show_type_img" style="width: 120px;" class="show-img" alt="" />
            </template>

            <img
              class="imgsq"
              v-if="item.icon_style.startsWith('http')"
              slot="reference"
              :src="item.icon_style"
              alt=""
            />
            <span v-else :class="[item.icon_style]" slot="reference" class="icon"></span>
          </el-popover>
          <div class="ellipsisText text" :title="item.name">{{ item.name }}</div>
        </template>
        <template v-else>
          <img
            class="imgsq"
            v-if="item.icon_style.startsWith('http')"
            :src="item.icon_style"
            alt=""
            @click="urlBtn(item)"
          />
          <span v-else :class="[item.icon_style]" @click="urlBtn(item)" class="icon"></span>
          <div class="ellipsisText text" :title="item.name">{{ item.name }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      asideNav: [],
    };
  },
  created() {
    this.getAsideIcon();
  },
  mounted() {},
  methods: {
    getAsideIcon() {
      this.$axios
        .get("/setting/side_nav")
        .then(res => {
          if (res.code == 200) {
            let data = res.data?.value;
            if (data && data.is_on) {
              this.asideNav = res.data.value.side_nav;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    urlBtn(item) {
      if (item.show_type_url) {
        window.open(item.show_type_url, "_black");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.aside-box {
  position: fixed;
  top: 50%;
  right: 30px;
  z-index: 10;
  width:52px;
  padding: 15px 0 5px;
  overflow: hidden;
  border-radius: 26px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(11, 30, 60, 0.15);
  .icon-item {
    margin: 0 auto 10px;
    width: 30px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    &:hover {
      .icon {
        color: #4e8bef;
      }
      .text {
        color: #4e8bef;
      }
    }
    .icon {
      font-size: 22px;
      color: #666666;
    }
    .imgsq {
      width: 22px;
      height: 22px;
    }
    .text {
      margin-top:2px;
      width: 36px;
      line-height: 14px;
      font-size: 12px;
      text-align: center;
      color: #666666;
    }
    .ellipsisText2{
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
    .show-text {
      position: absolute;
      width: 400px;
    }
    .show-img {
      width: 120px;
    }
  }
}
</style>
